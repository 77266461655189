import React, { useRef, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CopyJSONModal from '../nontemplatedRecognition/CopyJSONModal';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const DisplayData = ({ recData, result, documentUrl, formType, fileType, onSaveChanges, nufarm, userName }) => {
  console.log("documentUrl", documentUrl)
  console.log("formType", formType)
  console.log("fileType", fileType)
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const searchPluginInstance = searchPlugin();
  const [loading, setLoading] = useState(false);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editableValue, setEditableValue] = useState('');
  const [modifiedData, setModifiedData] = useState([...result]);
  const canvas = useRef();
  const recogImg = useRef();

  const fileUrl = formType === 'read' ?
    `data:${fileType};base64,${documentUrl}`
    : documentUrl;

  console.log("fileUrl", fileUrl)

  const handleSearchClick = (value) => {
    const stringValue = String(value).trim();
    const words = stringValue.replace(/\n/g, ' ').split(' ').filter(Boolean); // Split words and filter out empty strings
    setLoading(true);
    searchPluginInstance.clearHighlights();

    words.forEach((word) => {
      searchPluginInstance.highlight({
        keyword: word,
        matchCase: true,
        wholeWords: false,
      });
    });

    setLoading(false);
  };

  const handleClearHighlights = () => {
    searchPluginInstance.clearHighlights();
    setLoading(false);
  };

  const handleEditClick = (index, currentValue) => {
    setEditableIndex(index);
    setEditableValue(currentValue);
    handleClearHighlights();
  };

  const handleSaveClick = () => {
    const updatedData = [...modifiedData];
    updatedData[editableIndex].value = editableValue;
    console.log("updatedData", updatedData)
    setModifiedData(updatedData);
    onSaveChanges(updatedData);
    setEditableIndex(null);
    setEditableValue('');
  };

  return (
    <div className="container-fluid" style={{ height: '100vh', display: 'flex', overflow: 'hidden' }}>
      <div className="row" style={{ height: '100%', width: '100%', margin: '0' }}>
        {/* PDF Container */}
        <div className="col-md-8" style={{ height: '100%', overflow: 'hidden', padding: '0' }}>
          {loading && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000
            }}>
              <Typography variant="h6">Searching...</Typography>
            </div>
          )}
          <div
            className="rpv-core__viewer"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              opacity: loading ? 0.3 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            {!nufarm && (
              <div style={{ flex: 1, overflow: 'hidden' }}>
                {userName !== "dossdemo" && fileType === 'application/pdf' && (
                  <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
                    <Viewer
                      fileUrl={fileUrl}
                      plugins={[searchPluginInstance]}
                    />
                  </Worker>
                )}
                {userName !== "dossdemo" && fileType !== 'application/pdf' && fileType !== 'image/tiff' && (
                  // <canvas
                  //   id="canvas"
                  //   width="700"
                  //   height="900"
                  //   ref={canvas}
                  //   style={{ zIndex: 100 }}
                  // >
                  <img
                    ref={recogImg}
                    src={documentUrl}
                    alt=""
                    width={700}
                    height={900}
                  />
                  // </canvas>
                )}
                {fileType !== 'application/pdf' && fileType === 'image/tiff' && (
                  <img
                    ref={recogImg}
                    src={documentUrl}
                    alt=""
                    width={700}
                    height={900}
                  />
                )}
                {userName === "dossdemo" && (
                  <embed
                    src={`data:application/pdf;base64,${documentUrl}`}
                    width="100%"
                    height="600px"
                    type="application/pdf"
                  ></embed>
                )}
              </div>
            )}
            {nufarm === true && recData && recData.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(recData[0]).map((each, id) => (
                        <TableCell key={id}>{each}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recData.map((tdata, i) => (
                      <TableRow key={i}>
                        <TableCell>{tdata["BOL Number"]}</TableCell>
                        <TableCell>{tdata["CPO Number"]}</TableCell>
                        <TableCell>{tdata["Delivery Number"]}</TableCell>
                        <TableCell>{tdata["SO Number"]}</TableCell>
                        <TableCell>{tdata["To"]}</TableCell>
                        <TableCell>{tdata["Trip Number"]}</TableCell>
                        <TableCell>
                          <Link href={tdata["url"]} target="_blank" rel="noopener">
                            <img
                              src={tdata["url"]}
                              alt="Document Preview"
                              width="100px"
                              height="100px"
                            />
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>

        {/* Text Container */}
        {
          formType === 'read' ? (
            <div className="col-md-4" style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0', overflow: 'hidden' }}>
              <div className="result-container" style={{ flex: 1, overflowY: 'auto', padding: '1rem' }}>
                {
                  result?.map((page, index) => (
                    <Card
                      key={index}
                      style={{
                        marginBottom: '1.5rem',
                        padding: '1rem',
                        border: '1px solid #ddd',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" style={{ marginBottom: '1rem', fontWeight: 'bold', fontSize: '18px' }}>
                          Page {page.page}
                        </Typography>
                        {page.rawJson.analyzeResult.readResults[0].lines.map((line, lineIndex) => (
                          <Typography key={lineIndex} paragraph style={{ fontSize: '16px', color: '#333' }}>
                            {line.words.map((word, wordIndex) => (
                              <span
                                key={wordIndex}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '2px',
                                  display: 'inline-block',
                                  position: 'relative',
                                }}
                              >
                                {word.text}
                              </span>
                            ))}
                            <IconButton
                              aria-label="search"
                              onClick={() => handleSearchClick(line.words.map(word => word.text).join(' '))}
                              style={{ marginLeft: '10px', padding: '5px', color: '#007BFF', verticalAlign: 'middle' }}
                            >
                              <SearchIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Typography>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                }
              </div>
            </div>
          ) : formType === 'GATI Document' && result.map((item) => item.hasOwnProperty("confidence")) ? (
            <div>
              <Typography
                sx={{
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignSelf: "flex-start",
                }}
                color="text.secondary"
              // gutterBottom
              >
                Confidence
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h6" component="div" sx={{ width: "60%" }}>
                  <span style={{ padding: '5px 10px' }}>
                    {
                      result.map((item, index) =>
                        item.confidence ? `${item.confidence * 100} %` : ""
                      ).filter(Boolean).join(", ")
                    }
                  </span>
                </Typography>
              </div>
            </div>
          ) : formType === 'VIN' ? (
            <div>
              {Object.keys(recData[0].formData).map((key, index) => (
                recData[0].formData[key][0] && (
                  <>
                  <Card sx={{ minWidth: 275 }} key={index} style={{
                    marginBottom: '1rem',
                    padding: '1rem',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                  }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginBottom: '0.5rem' }} color="text.primary">
                        {recData[0].formData[key][0].key}
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="div" sx={{ width: "60%" }}>
                          {editableIndex === index ? (
                            <TextField
                              value={editableValue}
                              onChange={(e) => setEditableValue(e.target.value)}
                              variant="outlined"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          ) : (
                            <span style={{ fontSize: '12px', color: '#333' }}>
                              {recData[0].formData[key][0].value || ""}
                            </span>
                          )}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', width: '40%', justifyContent: 'space-between' }}>
                          <div style={{ marginLeft: '10px' }}>
                            <CopyJSONModal itemClicked={recData[0].formData[key][0].key} />
                          </div>
                          {editableIndex === index ? (
                            <Tooltip title="Save" arrow>
                              <IconButton
                                aria-label="save"
                                onClick={handleSaveClick}
                                style={{ padding: '5px', color: '#007BFF' }}
                              >
                                <SaveIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleEditClick(index, recData[0].formData[key][0].value)}
                                style={{ padding: '5px', color: '#007BFF' }}
                              >
                                <EditIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {fileType === 'application/pdf' &&
                            <Tooltip title="Search PDF" arrow>
                              <IconButton
                                aria-label="search"
                                onClick={() => handleSearchClick(recData[0].formData[key][0].value)}
                                style={{ padding: '5px', color: '#007BFF' }}
                              >
                                <SearchIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                            </Tooltip>
                          }
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  </>
                )
              ))}
            </div>
          ) : <div className="col-md-4" style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0', overflow: 'hidden' }}>
            <div className="result-container" style={{ flex: 1, overflowY: 'auto', padding: '1rem' }}>
              {result?.map((item, index) => (
                <Card sx={{ minWidth: 275 }} key={index} style={{
                  marginBottom: '1rem',
                  padding: '1rem',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginBottom: '0.5rem' }} color="text.primary">
                      {item?.key}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="h6" component="div" sx={{ width: "60%" }}>
                        {editableIndex === index ? (
                          <TextField
                            value={editableValue}
                            onChange={(e) => setEditableValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          <span style={{ fontSize: '12px', color: '#333' }}>
                            {item?.value || ""}
                          </span>
                        )}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center', width: '40%', justifyContent: 'space-between' }}>
                        <div style={{ marginLeft: '10px' }}>
                          <CopyJSONModal itemClicked={item} />
                        </div>
                        {editableIndex === index ? (
                          <Tooltip title="Save" arrow>
                            <IconButton
                              aria-label="save"
                              onClick={handleSaveClick}
                              style={{ padding: '5px', color: '#007BFF' }}
                            >
                              <SaveIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEditClick(index, item.value)}
                              style={{ padding: '5px', color: '#007BFF' }}
                            >
                              <EditIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {fileType === 'application/pdf' &&
                          <Tooltip title="Search PDF" arrow>
                            <IconButton
                              aria-label="search"
                              onClick={() => handleSearchClick(item.value)}
                              style={{ padding: '5px', color: '#007BFF' }}
                            >
                              <SearchIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        }
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default DisplayData;
