import React, { useState, useEffect } from 'react';
import loadingimg from '../../images/Docketry_Recognizing.gif';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Typography, Box, Breadcrumbs, Link, Stack } from '@mui/material';
const Extracting = (props) => {
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          padding: '16px',
          textAlign: 'center',
          maxHeight: '90vh',
          overflowY: 'auto',
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
        }}
      >
        {!props.isIntelligent && (
          <Breadcrumbs aria-label="breadcrumb" sx={{ justifyContent: 'center', mb: 2, color: 'white' }}>
            <Link href="/data-recognition" underline="hover" color="inherit">
              Choose Template
            </Link>
            <Link href="/uploadfile" underline="hover" color="inherit">
              Upload Document
            </Link>
            <Typography color="text.primary">Extract</Typography>
          </Breadcrumbs>
        )}

        <Box
          sx={{
            backgroundColor: '#008080',
            borderRadius: '8px',
            py: 3,
            px: 2,
            mb: 3,
            width: '100%',
          }}
        >
          <Typography variant="h4" component="h2" color="white" fontWeight="bold">
            Data Extraction in Progress...
          </Typography>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant="h6" color="#bbbbbb">
              Please wait...
            </Typography>
            <Typography variant="h5" color="#d9534f">
              Do not press refresh or back button
            </Typography>
          </Stack>
        </Box>

        {/* Image Box Section */}
        <Box
          sx={{
            width: '100%', // Full width of the container
            maxWidth: '600px', // Maximum width for the image box
            height: '50vh', // Set height for the image box
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: 2, // Padding on left and right for gaps
          }}
        >
          <img
            src={loadingimg} // Ensure this is the correct path to your image
            alt="loading"
            style={{
              width: '70%', // Fill the width of the box
              height: 'auto', // Maintain aspect ratio
              objectFit: 'contain', // Ensure the image is contained within the box
            }}
          />
        </Box>
      </Container>
    </>
  );
};
export default Extracting;
