/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment, useRef, useCallback } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import CsvDownloader from "react-csv-downloader";
import { ToastContainer, toast } from "react-toastify";
import { updateResult } from "../../actions/scan";
import ReactPaginate from "react-paginate";
import Extracting from "../data_recognition/extracting";
import { useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DataDisplay from './DataDisplay';
import DGPOCTable from "./DGPOCTable";
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import FilePreview from "../common/FilePreview";
import PdfViewerComponent from "../common/PdfViewerComponent";
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useTheme } from '@mui/material/styles';
import DisplayData from "./DisplayData";

// import TiffViewer from "../NonTemplatedflow/TiffViewer";


const PER_PAGE = 1;

function NonTemplatedRec({
  result: { recData, loading, template, nufarm, dossdemo, formType, fileName, documentUrl },
  updateResult,
}) {
  console.log("recData@@@@--------------------: ", recData);
  console.log("fileName", fileName)
  // console.log("template: ", template);
  // console.log("nufarm: ", nufarm);

  const userName = JSON.parse(localStorage.getItem("userDetails")).username;
  // console.log("userName: ", userName);

  let history = useHistory();
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(0);
  const [ResData, setData] = useState({
    res: [],
    NumOfPages: null,
  });

  const [ResData2, setData2] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [dataNum, setDataNum] = useState(true);
  const [docs, setDocs] = useState([]);
  const [rectPoint, setRectPoint] = useState([]);
  const [docNumber, setDocNumber] = useState(1);
  const [uploadedBase64, setUploadedBase64] = useState(
    JSON.parse(localStorage.getItem("base64"))
  );
  const fileType = localStorage.getItem('fileType')
  const [CSV, setCSV] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // console.log('uploadedBase64: ',localStorage.getItem('base64'))
  // console.log(`uploadedBase64.split(';')[0].split(':')[1]: `, uploadedBase64?.split(';')[0]?.split(':')[1])

  const [state, setState] = useState({
    MutiPage: false,
    sloading: false,
    isSaved: false,
    resultId_: [],
    templateType: null,
  });

  const [updatedRecData, setUpdatedRecData] = useState(recData && recData[0] ? recData[0].rawJson : null);
  const [hasChanges, setHasChanges] = useState(true);
  const canvas = useRef();
  const recogImg = useRef();

  useEffect(() => { }, [state.isSaved]);

  useEffect(() => {
    loadCommentsFromServer();
  }, [recData]);

  useEffect(() => {
    saveAfterScan();
    setUploadedBase64(JSON.parse(localStorage.getItem("base64")));
  }, [recData]);

  useEffect(() => {
    displayBox();
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // Custom styles for the table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: "bold",
    padding: "12px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td': {
      padding: '10px 15px',
    },
  }));


  function saveAfterScan() {
    // console.log("recData", recData);
    if (formType !== "nonstandardoptimumnetconsent" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "optimumnetconsent" && formType !== "wondercement" && recData !== null && recData.length == undefined) {
      if (recData.formData && state.isSaved === false && dataNum) {
        let resultArray = [];
        resultArray.push({
          savedresult: recData.formData,
          page: 1,
        });
        let body = {
          scannedResultImage: template.userFileName,
          base64: template.base64,
          result: resultArray,
          numOfPages: 1,
          formtype: template.type,
        };
        // console.log("body", body);
        axios.post("/result/saveIn", body).then(function (response) {
          if (response.status == 200) {
            notify("Result Saved", "success");
            const result_ = response.data;
            const resultId = result_._id;
            setState({
              ...state,
              sloading: false,
              // isSaved: true,
              templateType: recData.formData[0].type,
            });
            state.resultId_.push(resultId);
          } else {
            notify("Something happened");
          }
        });
      }
    } else {
      if (recData && state.isSaved === false && dataNum) {
        let resultArray = [];
        resultArray.push({
          savedresult: recData,
          page: 1,
        });
        let body = {
          scannedResultImage: template.userFileName,
          base64: template.base64,
          result: resultArray,
          numOfPages: 1,
          formtype: template.type,
        };
        // console.log("body", body);
        axios.post("/result/saveIn", body).then(function (response) {
          if (response.status == 200) {
            notify("Result Saved", "success");
            const result_ = response.data;
            const resultId = result_._id;
            setState({
              ...state,
              sloading: false,
              // isSaved: true,
              templateType: recData[0]?.type,
            });
            state.resultId_.push(resultId);
          } else {
            notify("Something happened");
          }
        });
      }
    }
  }

  const CSVData = (inputData) => {
    // console.log('inside CSVData: ', inputData)
    if (userName !== 'dossdemo') {
      return inputData?.formData?.map((each) => ({ "key": each.key, "value": each.value }))
    } else {
      return inputData?.formData?.map((each) => ({ "key": each.key, "value": each.value }))
    }
  }

  const dataForCSV = []
  for (let i = 0; i < recData?.data?.length; i++) {
    let obj = {}
    recData[0]?.rawJson?.columns?.forEach((eachCol, indx) => {
      obj[eachCol] = recData?.data[i][indx]
    })
    dataForCSV.push(obj)
  }
  // console.log('csv data: ', dataForCSV)

  const headers = recData?.columns?.map((each, indx) => {
    return ({
      label: each, key: each
    })
  })

  const csvReport = {
    filename: "report.csv",
    headers: headers,
    data: dataForCSV
  }

  const downloadCSV = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // const exportToCSV = e => {
  //   let headers = []
  //   e.preventDefault()
  //   let csvData = recData?.map((eachRecData, id) => {
  //     if (eachRecData?.rawJson) {
  //       headers = ['key, value, confidence, \n']
  //       console.log('eachRecData when rawJson true: ', eachRecData?.rawJson)
  //       return eachRecData?.rawJson.reduce((acc, eachEntry) => {
  //         // console.log('eachEntry: ', eachEntry)
  //         const { key, value, confidence } = eachEntry
  //         acc.push([key, value.slice(0, -2), confidence, '\n'])
  //         return acc
  //       }, [])
  //     } else if (!eachRecData?.rawJson) {
  //       // console.log('eachRecData when rawJson undefined: ', eachRecData);
  //       let headerArr = [...eachRecData?.header];
  //       // console.log('headerArr: ', headerArr)
  //       let headerOfCsv = headerArr.join()
  //       headers = [headerOfCsv]
  //       // console.log('headers after join: ', headers)
  //       let arrToReturn = eachRecData?.rows?.map((eachArr, idx) => {
  //         let eachCSVStr = '\n' + eachArr.join()
  //         // console.log('eachCSVStr: ', eachCSVStr)
  //         return [eachCSVStr]
  //       })
  //       return arrToReturn
  //     }

  //   })
  //   // console.log('csvData in exportToCSV: ', csvData)
  //   downloadCSV({
  //     // data: [...headers].join('\n'),
  //     data: [...headers, ...csvData].join('\n'),
  //     fileName: 'extractedData.csv',
  //     fileType: 'text/csv',
  //   })
  // }

  const saveApiContentAsExcel = (apiResponse, fileName) => {
    const blob = new Blob([apiResponse.data], { type: apiResponse.headers['content-type'] });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and filename
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  const pdfDownload = (data) => {
    debugger
    const compressedData = data.data; // Assuming responseData contains the compressed data
    const blob = new Blob([compressedData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'output.pdf'; // Set the desired file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const zipDownload = (data, fileLabel) => {
    const compressedData = data; // Assuming responseData contains the compressed data
    const blob = new Blob([compressedData], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'file.zip'; // Set the desired file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onDownload = () => {
    if (formType === "optimumnet" || formType === "optimumnetconsent" || formType === "nonstandardoptimumnetconsent" || formType === "dandb") {
      const fileLabel = fileName.split('.')[0]
      saveApiContentAsExcel(recData, `${fileLabel}.xlsx`)
    } else if (formType.toLowerCase() === "bajaj" || formType.toLowerCase() === "wondercement" || formType.toLowerCase() === "canara") {
      const fileLabel = fileName.split('.')[0]
      zipDownload(recData.data, fileLabel)
    } else if (formType.replace(/\s/g, '').toLowerCase() === "ameristarusareport") {
      pdfDownload(recData)
    }
  }
  /*============================== Function for JSON Creation End ================================*/

  function loadCommentsFromServer() {
    if (formType !== "nonstandardoptimumnetconsent" && formType !== "optimumnet" && formType.toLowerCase() !== "bajaj" && formType.toLowerCase() !== "wondercement" && formType !== "optimumnetconsent" && recData !== null && recData.length == undefined) {
      let resultObj = [];
      resultObj.push({ RecognitionData: JSON.stringify(recData.formData) });
      setData((oldState) => {
        return { ...oldState, res: resultObj, NumOfPages: 1 };
      });
      let temp_resData2 = [...ResData2];
      temp_resData2.push({ res: resultObj, NumOfPages: 1 });
      setData2((old) => {
        return temp_resData2;
      });
      let temp_dataArray = [...dataArray];
      temp_dataArray.push(recData.formData);
      setDataArray((old) => temp_dataArray);
    } else if (recData && dataNum) {
      let resultObj = [];
      resultObj.push({
        RecognitionData: JSON.stringify(recData),
      });

      setData((oldState) => {
        return { ...oldState, res: resultObj, NumOfPages: 1 };
      });
      let temp_resData2 = [...ResData2];
      temp_resData2.push({ res: resultObj, NumOfPages: 1 });
      setData2((old) => {
        return temp_resData2;
      });
      let temp_dataArray = [...dataArray];
      temp_dataArray.push(recData);
      setDataArray((old) => temp_dataArray);
    }
  }

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const downloadFile = async (data, filename) => {
    // console.log('data: ', data, 'filename: ', filename)
    //const {myData} = this.state; // I am assuming that "this.state.myData"
    const fileName = filename;
    const json = data;
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function displayBox(el) {
    // console.log("inside displayBox function: ", el);
    // if (uploadedBase64?.split(";")[0]?.split(":")[1] !== fileType) {
    if (fileType !== "application/pdf") {

      var c = canvas.current;
      // console.log('c: ', c)
      var ctx = await c?.getContext("2d");
      var imgElement = recogImg.current;
      // console.log('imgElement: ', imgElement)
      // console.log('ctx in displayBox: ', ctx)
      ctx?.drawImage(imgElement, 0, 0, 700, 900);
      if (
        el &&
        el?.hasOwnProperty("point") &&
        el?.hasOwnProperty("bounding_box")
      ) {
        ctx.beginPath();
        ctx.lineWidth = "3";
        ctx.strokeStyle = "blue";
        // console.log('points: ', el.point[0], el.point[1])
        var imgLen = Number(JSON.parse(localStorage.getItem("imgLen")));
        var imgHt = Number(JSON.parse(localStorage.getItem("imgHt")));
        const startX =
          imgLen <= imgHt
            ? (0.41 * (1688 / imgLen) * el.point[0]).toFixed(3)
            : (0.27 * (1688 / imgHt) * el.point[0]).toFixed(3); //2450
        const startY =
          imgLen <= imgHt
            ? (0.3 * (3000 / imgHt) * el.point[1]).toFixed(3)
            : 0.47 * ((3000 / imgLen) * el.point[1]).toFixed(3); //3500
        // const startX = 0.41*(1688/imgLen)*el.point[0]   //2450
        // const startY = 0.24*(3000/imgHt)*el.point[1]    //3500
        const rectLong =
          imgLen <= imgHt
            ? 0.41 *
            (Math.max(
              el.bounding_box.key_value[1].x,
              el.bounding_box.key_value[2].x
            ) -
              Math.min(
                el.bounding_box.key_value[0].x,
                el.bounding_box.key_value[3].x
              ))
            : 1.2 *
            (Math.max(
              el.bounding_box.key_value[1].x,
              el.bounding_box.key_value[2].x
            ) -
              Math.min(
                el.bounding_box.key_value[0].x,
                el.bounding_box.key_value[3].x
              ));
        const rectWide =
          imgLen <= imgHt
            ? 0.3 *
            (Math.max(
              el.bounding_box.key_value[1].y,
              el.bounding_box.key_value[2].y
            ) -
              Math.min(
                el.bounding_box.key_value[0].y,
                el.bounding_box.key_value[3].y
              ))
            : 2 *
            (Math.max(
              el.bounding_box.key_value[1].y,
              el.bounding_box.key_value[2].y
            ) -
              Math.min(
                el.bounding_box.key_value[0].y,
                el.bounding_box.key_value[3].y
              ));
        // console.log("reactangle: startX, startY, rectLong, rectWide > ",startX, startY, rectLong, rectWide)
        ctx.rect(startX, startY, rectLong, rectWide);
        ctx.stroke();
      }
    }
  };

  const uploadedFileUrl = localStorage.getItem('upLoadedFileUrl')

  const offset = currentPage * PER_PAGE;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createAndDownloadFile = (content, fileName, mimeType) => {
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // CSV Export Function
  const exportToCSV = () => {
    const jsonData = recData[0].rawJson;
    const updatedFileName = fileName ? fileName.split('.')[0] : 'Report';

    let csvContent = "Key,Value\n";
    jsonData.forEach(item => {
      const key = item.key;
      let value = item.value;

      if (typeof value === 'string') {
        value = value.replace(/\n/g, " ");
      } else if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      csvContent += `${key},${value}\n`;
    });
    createAndDownloadFile(csvContent, `${updatedFileName}.csv`, 'text/csv;charset=utf-8;');
  };

  // XML Export Function
  const exportToXML = () => {
    const jsonData = recData[0].rawJson;
    const updatedFileName = fileName ? fileName.split('.')[0] : 'Report';

    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<root>\n';
    jsonData.forEach(item => {
      const key = item.key;
      let value = item.value;

      if (typeof value === 'string') {
        value = value.replace(/\n/g, " ");
      } else if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      xmlContent += `  <item>\n    <key>${key}</key>\n    <value>${value}</value>\n  </item>\n`;
    });
    xmlContent += '</root>';
    createAndDownloadFile(xmlContent, `${updatedFileName}.xml`, 'application/xml;charset=utf-8;');
  };

  // JSON Export Function
  const exportToJSON = () => {
    const jsonData = recData[0].rawJson.map(({ key, value }) => ({ key, value }));
    const jsonString = JSON.stringify(jsonData, null, 2);
    const updatedFileName = fileName ? fileName.split('.')[0] : 'Report';
    createAndDownloadFile(jsonString, `${updatedFileName}.json`, 'application/json;charset=utf-8;');
  };

  const currentPageData = ResData2?.map(
    (item, index) =>
      item.res.slice(offset, offset + PER_PAGE).map((it, index2) =>
        index == docNumber - 1 ? (
          <div
            className="row"
            style={{ justifyContent: "space-between", paddingLeft: "15px" }}
          >
            <div className="col-sm-12">
              <div className="topiconIntelligent">
                {formType !== "read" && formType !== "gepowerconversion" && formType.replace(/\s/g, '').toLowerCase() !== "ameristarusareport" && formType !== "nonstandardoptimumnetconsent" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "wondercement" && formType !== "optimumnetconsent" && formType !== "canara" && formType !== "dandb" && <div className="topIconsRight">
                  <li>
                    <button
                      className="btn btn-yellow1 btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                      type="button"
                      onClick={(e) =>
                        save(e, it.RecognitionData, currentPage, index)
                      }
                    >
                      {" "}
                      <i
                        className="far fa-check-circle"
                        style={{ paddingRight: "10px" }}
                      >
                        {state.loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                      </i>
                      Save Changes
                    </button>
                  </li>

                  <li>
                    {/* <div> */}
                    <button
                      className="btn btn-yellow1 btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                      type="button"
                      // onClick={dataDownloadHandler}
                      style={{
                        paddingLeft: "10px",
                        width: "133.84px",
                        alignContent: "center",
                      }}
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                    >
                      <i
                        className="fas fa-download"
                        style={{ paddingRight: "10px" }}
                      >
                        {" "}
                      </i>
                      Download
                    </button>
                    <div className="dropdown-menu">
                      {/* <CsvDownloader
                        // datas={SaveAsCSV(JSON.parse(it?.RecognitionData))}
                        filename={template.type}
                        extension=".csv"
                        // separator=";"
                        wrapColumnChar="'"
                        // columns={columns}
                        // datas={JSON.parse(recData)}
                        datas={CSVData(recData)}
                        text="DOWNLOAD"
                        separator=","                   
                        
                      ><a className="dropdown-item" href="#">
                      Export as CSV
                    </a>
                      </CsvDownloader> */}
                      <Button
                        variant="outlined"
                        startIcon={<FileDownloadIcon />}
                        // onClick={exportToJson}
                        onClick={exportToCSV}
                      >
                        {/* <CSVLink {...csvReport}>Download CSV</CSVLink> */}
                        CSV
                      </Button>
                      {/* <CSVLink {...csvReport}>Export as CSV</CSVLink> */}
                      {/* <a
                        className="dropdown-item"
                        href="#"
                        role="presentation"
                        onClick={(e) =>
                          downloadFile(it.RecognitionData, template.type)
                        }
                      >
                        Export as JSON
                      </a> */}
                    </div>
                    {/* </div> */}
                  </li>
                </div>}
                {formType !== "read" && formType !== "gepowerconversion" && (formType === "optimumnet" || formType === "nonstandardoptimumnetconsent" || formType === "bajaj" || formType === "wondercement" || formType === "optimumnetconsent" || formType === "canara" || formType.replace(/\s/g, '').toLowerCase() === "ameristarusareport" || formType === "dandb") &&
                  <button
                    className="btn btn-yellow1 btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                    type="button"
                    onClick={onDownload}
                    style={{
                      paddingLeft: "10px",
                      width: "133.84px",
                      alignContent: "center",
                      cursor: "pointer"
                    }}
                    id="dropdownMenuButton"
                  // data-toggle="dropdown"
                  >Download</button>}
              </div>
            </div>

            {formType !== "read" && formType !== "nonstandardoptimumnetconsent" && formType.replace(/\s/g, '').toLowerCase() !== "ameristarusareport" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "wondercement" && formType !== "optimumnetconsent" && formType !== "gepowerconversion" && formType !== "canara" && formType !== "dandb" && recData !== null && recData?.map((eachRecData, idx) => {
              console.log('inside NonTemplateRecognition: ', eachRecData)
              if (eachRecData.hasOwnProperty("docType")) {
                // console.log('own property docType', JSON.parse(localStorage.getItem("tiff")))
                return (<Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={!nufarm ? 8 : 12}>
                      <Item>
                        {!nufarm && (
                          <div>
                            {/* {userName !== "dossdemo" && (
                          <canvas
                            id="canvas"
                            width="700"
                            height="900"
                            ref={canvas}
                            style={{ zIndex: 100 }}
                          > */}
                            {/* && uploadedBase64.split(";")[0].split(":")[1] !== 'image/tiff' */}
                            {!nufarm && userName !== "dossdemo" && (
                              ((fileType !== "application/pdf") && (fileType !== 'image/tiff')) &&
                              <canvas
                                id="canvas"
                                width="700"
                                height="900"
                                ref={canvas}
                                style={{ zIndex: 100 }}
                              ><img
                                  ref={recogImg}
                                  // src={template.templateData}
                                  src={uploadedFileUrl}
                                  alt=""
                                  width={700}
                                  height={900}
                                // style={{zIndex: -1}}
                                // id="recogImage"
                                /></canvas>)}

                            {!nufarm && userName !== "dossdemo" && (
                              (fileType === "application/pdf") && <embed
                                // file={prevImgSource}
                                src={uploadedFileUrl}
                                width="100%"
                                height="600px"
                                type="application/pdf"
                              // style={{zIndex: -1}}
                              // ref={recogImg}
                              // id="recogImage"
                              // onLoadSuccess={onImgLoad}
                              // onLoad={onImgLoad}
                              ></embed>
                            )}

                            {!nufarm && userName !== "dossdemo" && (
                              (fileType === 'image/tiff') &&
                              // <TiffViewer
                              //   className="scandoumentdiv"
                              //   src={localStorage.getItem("tiff")}
                              //   href="#"
                              //  alt="Loading..."
                              // />
                              // <canvas
                              //   id="canvas"
                              //   width="700"
                              //   height="900"
                              //   ref={canvas}
                              //   style={{ zIndex: 100 }}
                              // >
                              <img
                                ref={recogImg}
                                // src={template.templateData}
                                src={uploadedFileUrl}
                                alt=""
                                width={700}
                                height={900}
                              // style={{zIndex: -1}}
                              // id="recogImage"
                              />
                              // </canvas>
                            )}
                            {/* </div> */}
                            {/* </TransformComponent> */}
                            {/* </TransformWrapper> */}
                            {/* </div>   */}
                            {/* </canvas>
                        )} */}
                            {nufarm && (
                              <embed
                                // file={prevImgSource}
                                src={eachRecData?.url}
                                width="100%"
                                height="600px"
                                type="application/pdf"
                              // style={{zIndex: -1}}
                              // ref={recogImg}
                              // id="recogImage"
                              // onLoadSuccess={onImgLoad}
                              // onLoad={onImgLoad}
                              ></embed>
                            )}
                            {userName === "dossdemo" && (
                              <embed
                                // file={prevImgSource}
                                src={
                                  userName === "dossdemo"
                                    ? `data:application/pdf;base64,${recData.base64}`
                                    : recData.url
                                }
                                width="100%"
                                height="600px"
                                type="application/pdf"
                              // style={{zIndex: -1}}
                              // ref={recogImg}
                              // id="recogImage"
                              // onLoadSuccess={onImgLoad}
                              // onLoad={onImgLoad}
                              ></embed>
                            )}

                          </div>
                        )}
                        {nufarm === true && recData && recData?.length > 0 && (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                {Object.keys(recData[0]).map((each, id) => (
                                  <th>{each}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {recData?.map((tdata, i) => (
                                <tr>
                                  <td>{tdata["BOL Number"]}</td>
                                  <td>{tdata["CPO Number"]}</td>
                                  <td>{tdata["Delivery Number"]}</td>
                                  <td>{tdata["SO Number"]}</td>
                                  <td>{tdata["To"]}</td>
                                  <td>{tdata["Trip Number"]}</td>
                                  <td>
                                    {
                                      <a target={"_blank"} href={tdata["url"]}>
                                        <img
                                          src={tdata["url"]}
                                          width="100px"
                                          height="100px"
                                        />
                                      </a>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Item>
                    </Grid>
                    {eachRecData && !eachRecData?.docType?.includes("Signature") && <Grid item xs={4}>
                      <div style={{ overflowY: 'scroll', maxHeight: "600px" }}>
                        <Item>
                          {
                            // recData?.length>0 &&
                            (eachRecData?.docType?.includes("Form") ||
                              eachRecData?.docType?.includes("Passport") ||
                              eachRecData?.docType?.includes("Driver's License") ||
                              eachRecData?.docType?.includes("Aadhar") || eachRecData?.docType?.includes("GATI Document"))
                              ? eachRecData?.rawJson.map((el, index) => <DataDisplay DataToDisplay={el} displayBox={displayBox} docType={eachRecData?.docType} />)
                              : eachRecData &&
                                (eachRecData?.docType?.includes("Bank Statement") ||
                                  eachRecData?.docType?.includes("Insurance Claim"))
                                ? eachRecData?.rawJson.map((el, index) => <DataDisplay DataToDisplay={el} displayBox={displayBox} />)
                                : eachRecData &&
                                  (eachRecData?.docType?.includes("Receipt") ||
                                    eachRecData?.docType?.includes("Invoice"))
                                  ? eachRecData?.rawJson.map((el, indx) => <DataDisplay DataToDisplay={el} displayBox={displayBox} />)
                                  : eachRecData &&
                                    (eachRecData?.docType?.includes("QR Code Reader"))
                                    ? eachRecData?.rawJson.map((el, indx) => <DataDisplay DataToDisplay={el} displayBox={displayBox} />)
                                    // : (eachRecData?.docType?.includes("Signature"))
                                    // ? eachRecData?.rawJson.map((el, indx) => <DataDisplay DataToDisplay={el} displayBox={displayBox} imageAtDataDisplay={true}/>)
                                    : userName === "dossdemo"
                                      ? eachRecData?.formData.map((el, indx) => <DataDisplay DataToDisplay={el} displayBox={displayBox} />)
                                      : eachRecData?.docType?.includes("VIN") && eachRecData?.formData?.map((el, index) => {
                                        // console.log('VIN: ', el[1][0])
                                        return <DataDisplay DataToDisplay={el[1][0]} displayBox={displayBox} />
                                      })

                          }
                          {/* </div> */}
                          <br />
                          <br />
                          {/* </div> */}
                        </Item></div>
                    </Grid>}
                  </Grid>
                </Box>)
              }
            })}

          </div>
        ) : null
      )
    //Row here
  );
  //HERE -------

  const pageCount = Math.ceil(ResData.NumOfPages / PER_PAGE);

  const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };

  const handleSaveChanges = (modifiedData) => {
    toast.success("Data Saved", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    // if (JSON.stringify(updatedRecData) !== JSON.stringify(recData?.[0]?.rawJson || [])) {
    setUpdatedRecData(modifiedData);
    // setHasChanges(true);
    // }
  };

  const saveChanges = () => {
    toast.success("Data Saved", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    updateResult(updatedRecData);
    // setHasChanges(false);
  };

  const getResult = (formType) => {
    if (formType === "gepowerconversion") {
      return (
        <div style={{ height: "200" }}>
          <FilePreview data={recData?.data} /></div>
      )
    } else if (formType === "read") {
      return (<div style={{ height: "200" }}>
        <DisplayData result={recData?.data} documentUrl={documentUrl} formType={formType} fileType={fileType} />
      </div>)
    } else {
      return (
        <Box sx={{ height: "100%" }}>
          <Box className="col-sm-12">
            <Box className="topiconIntelligent">
              {formType !== "read" &&
                formType !== "gepowerconversion" &&
                formType.replace(/\s/g, "").toLowerCase() !== "ameristarusareport" &&
                formType !== "nonstandardoptimumnetconsent" &&
                formType !== "optimumnet" &&
                formType !== "bajaj" &&
                formType !== "wondercement" &&
                formType !== "optimumnetconsent" &&
                formType !== "canara" &&
                formType !== "dandb" && (
                  <Box className="topIconsRight">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<FileDownloadIcon />}
                      onClick={handleClick}
                      sx={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        textTransform: "none",
                        fontSize: "12px",
                      }}
                    >
                      Download
                    </Button>

                    <Menu
                      id="download-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "download-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          exportToCSV();
                          handleClose();
                        }}
                        sx={{ width: "113px" }}
                      >
                        <InsertDriveFileIcon
                          style={{ marginRight: "8px", color: theme.palette.primary.main }}
                        />
                        CSV
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          exportToXML();
                          handleClose();
                        }}
                        sx={{ width: "113px" }}
                      >
                        <TextSnippetIcon
                          style={{ marginRight: "8px", color: theme.palette.primary.main }}
                        />
                        XML
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          exportToJSON();
                          handleClose();
                        }}
                        sx={{ width: "113px" }}
                      >
                        <DescriptionIcon
                          style={{ marginRight: "8px", color: theme.palette.primary.main }}
                        />
                        JSON
                      </MenuItem>
                    </Menu>
                  </Box>
                )}

              {formType !== "read" &&
                formType !== "gepowerconversion" &&
                (formType === "optimumnet" || formType === "nonstandardoptimumnetconsent" || formType === "bajaj" || formType === "wondercement" || formType === "optimumnetconsent" || formType === "canara" || formType.replace(/\s/g, '').toLowerCase() === "ameristarusareport" || formType === "dandb") && 
                (
                  <Box className="topIconsRight">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<FileDownloadIcon />}
                      onClick={onDownload}
                      sx={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        textTransform: "none",
                        fontSize: "12px",
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>

          <DisplayData
            recData={recData}
            result={recData[0].rawJson}
            documentUrl={uploadedFileUrl}
            formType={recData[0].docType}
            fileType={fileType}
            onSaveChanges={handleSaveChanges}
            nufarm={nufarm}
            userName={userName}
          />
          <Box className="Sspace"></Box>
          {formType !== "nonstandardoptimumnetconsent" &&
            formType !== "read" &&
            formType.replace(/\s/g, "").toLowerCase() !== "ameristarusareport" &&
            formType !== "gepowerconversion" &&
            formType !== "optimumnet" &&
            formType !== "bajaj" &&
            formType !== "wondercement" &&
            formType !== "optimumnetconsent" &&
            formType !== "canara" &&
            formType !== "dandb" &&
            recData !== null &&
            Array.isArray(recData) &&
            recData?.length > 0 &&
            recData.map((eachResponse, idx) => {
              if (eachResponse?.tableData && eachResponse?.tableData?.length > 0) {
                return eachResponse?.tableData?.map((eachDoc, docIndx) => {
                  return eachDoc?.tables?.map((tdata, indx) => {
                    return (
                      <Box key={`table-${docIndx}-${indx}`} sx={{ margin: "10px 10px 10px 10px" }}>
                        <TableContainer component={Paper} elevation={3} sx={{ padding: "20px" }}>
                          <Table>
                            <TableHead>
                              <StyledTableRow>
                                {tdata?.header?.map((eachHeaderData, th_index) => (
                                  <StyledTableCell key={`header-${th_index}`} align="center">
                                    {eachHeaderData}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {tdata?.rows?.map((eachRow, tr_index) => (
                                <StyledTableRow key={`row-${tr_index}`}>
                                  {eachRow?.map((eachData, i) => (
                                    <TableCell key={`data-${i}`} align="center" sx={{ fontSize: "14px" }}>
                                      {eachData}
                                    </TableCell>
                                  ))}
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  });
                });
              }
              return null;
            })}
        </Box>
      )
    }
    // else {
    //   return (
    //     <div className="row">
    //       <div
    //         className="app-content container1"
    //         style={{ overflowY: "scroll", height: "200" }}
    //       >
    //         <div className="row">
    //           <div id="react-paginate">
    //             {/* {currentPageData} */}
    //             {pageCount !== 1 ? (
    //               <ReactPaginate
    //                 pageCount={pageCount}
    //                 onPageChange={handlePageClick}
    //                 containerClassName={"pagination"}
    //                 previousLinkClassName={"pagination__link"}
    //                 nextLinkClassName={"pagination__link"}
    //                 disabledClassName={"pagination__link--disabled"}
    //                 activeClassName={"active"}
    //               />
    //             ) : null}
    //           </div>
    //         </div>

    //         <div className="Sspace"></div>
    //         {formType !== "nonstandardoptimumnetconsent" && formType !== "read" && formType.replace(/\s/g, '').toLowerCase() !== "ameristarusareport" && formType !== "gepowerconversion" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "wondercement" && formType !== "optimumnetconsent" && formType !== "canara" && formType !== "dandb" && recData !== null && Array.isArray(recData) &&
    //           recData?.length > 0 &&
    //           recData?.map((eachResponse, idx) => {
    //             if (eachResponse?.tableData &&
    //               eachResponse?.tableData?.length > 0) {
    //               return (eachResponse?.tableData?.map((eachDoc, docIndx) => {
    //                 console.log('tableData eachDoc: ', eachDoc)
    //                 return (
    //                   <div>
    //                     {eachDoc?.tables?.map((tdata, indx) => {
    //                       console.log('tdata: ', tdata)
    //                       return (
    //                         <div>
    //                           <div className="Sspace"></div>
    //                           <div className="Sspace"></div>
    //                           <div
    //                             className="col-16 mt-0 audit-wrap"
    //                             id="activityLogTable"
    //                           >
    //                             <table className="table audit-table table-striped mb-0 col-4">
    //                               <thead
    //                                 className="p-tbody "
    //                                 style={{ fontSize: "8px" }}
    //                                 id="thead_actvtylog"
    //                               >
    //                                 {tdata?.header?.map((eachHeaderData, th_index) => {
    //                                   // console.log('tableheaderdata: ', eachHeaderData)
    //                                   return (
    //                                     <th
    //                                       id={th_index}
    //                                       className="w-4"
    //                                       scope="col"
    //                                     >
    //                                       {eachHeaderData}
    //                                     </th>
    //                                   )
    //                                 })}
    //                               </thead>
    //                               <tbody>
    //                                 {tdata?.rows?.map((eachRow, tr_index) => {
    //                                   // console.log('eachRow: ', eachRow)
    //                                   return (<tr>{eachRow?.map((eachData, i) => <td id={i}>{eachData}</td>)}</tr>)
    //                                 })}
    //                               </tbody>
    //                             </table>
    //                           </div>
    //                         </div>)
    //                     })}
    //                   </div>)
    //               }))
    //             }

    //           })}

    //         {formType !== "nonstandardoptimumnetconsent" && formType.replace(/\s/g, '').toLowerCase() !== "ameristarusareport" && formType !== "gepowerconversion" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "wondercement" && formType !== "optimumnetconsent" && formType !== "canara" && formType !== "dandb" && recData !== null && recData[0]?.hasOwnProperty("docType") && recData[0]?.docType === "Signature" &&
    //           recData?.map((eachRecData, idx) => {
    //             console.log('inside signature', eachRecData)
    //             return (eachRecData?.rawJson.map((el, indx) => <DataDisplay DataToDisplay={el} displayBox={displayBox} imageAtDataDisplay={true} />))
    //           })

    //         }


    //         {/* {recData?.map((each, id) => {
    //     if(!each.hasOwnProperty("docType")){
    //       return (<Box sx={{ flexGrow: 1 }}>
    //     <Grid container spacing={2}>
    //     <Grid item xs={12}>
    //     <Item>
    //               <embed
    //                 // title={'uploadedBase64'}
    //                 src={uploadedBase64 ? uploadedBase64 : JSON.parse(localStorage.getItem("base64"))}
    //                 width="100%"
    //                 height="600px"
    //                 type="application/pdf"
    //                 // style={{zIndex: 110}}
    //                 // ref={recogImg}
    //                 // id="recogImage"
    //                 // onLoadSuccess={onImgLoad}
    //                 // onLoad={onImgLoad}
    //               ></embed>
    //       </Item>
    //     </Grid>
    //     </Grid>
    //     </Box>)
    //     }
    //     })

    //     } */}
    //         {
    //           formType !== "read" && formType !== "gepowerconversion" && formType.replace(/\s/g, '').toLowerCase() !== "ameristarusareport" && formType !== "nonstandardoptimumnetconsent" && formType !== "optimumnet" && formType !== "bajaj" && formType !== "wondercement" && formType !== "optimumnetconsent" && formType !== "canara" && formType !== "dandb" && recData !== null && recData?.map((eachRecData, index) => {
    //             console.log('inside !docType')

    //             if (!(eachRecData.hasOwnProperty("docType"))) {
    //               return <DGPOCTable columns={eachRecData?.header} data={eachRecData?.rows || eachRecData?.rawJson} index={index} />
    //             }

    //           })

    //         }


    //       </div>


    //     </div>
    //   )
    // }

  }

  const save = async (e, res, page, index) => {
    // console.log('it.RecognitionData: ', res)
    e.preventDefault();
    let body = {
      resultID: state.resultId_[index],
      updatedResult: res,
      TemplateType: state.templateType,
      pageNumber: page + 1,
    };
    if (state.isSaved == false) {
      // axios.post("/result/updatedIn", body).then(function (response) {
      //   if (response.status == 200) {
      //     notify("Saved Changes", "success");
      //     setState({
      //       ...state,
      //       sloading: false,
      //       isSaved: true,
      //     });
      //   } else {
      //     notify("Something happened");
      //   }
      // });
    } else {
      notify("Already Saved");
    }
  };

  const recDataTpe = {
    DataToDisplay: "dada", displayBox: "asdasd", imageAtDataDisplay: uploadedBase64, docType: "optimumnet"
  }
  console.log("recData", recData, formType)
  return (
    <Fragment>
      <CssBaseline />
      <Container>
        <Box sx={{ bgcolor: "#008080", borderRadius: '8px' }}>
          {loading ? (
            <>
              <Extracting isIntelligent={true} />
            </>
          ) : (
            <>
              {/* <Extracting isIntelligent={true} /> */}
              {getResult(formType)}
            </>
          )}
        </Box>
      </Container>
    </Fragment>
  );
}

NonTemplatedRec.propTypes = {
  result: PropTypes.object,
  updateResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.getNontemplateform,
});

export default connect(mapStateToProps, { updateResult })(NonTemplatedRec);

